<template>
  <div id="profile-page">
    <div class="vx-row">
      <div class="vx-col w-full">
        <ProfileHeader />
      </div>
    </div>

  <div class="mt-3 profile-custom-tabs">
  <div class="vx-row"> 
    <div class="vx-col w-full">
       <vs-tabs alignment="center">
      <vs-tab label="My Posts">
        <div class="vx-col w-full md:w-full lg:w-full xl:w-1/2 mx-auto">
          <my-posts-V2 />
          <!-- <PartnerPostsList/> -->
          <!-- <MyFeeds/> -->
          
        </div>
      </vs-tab>
    
     
 
      

      <!--
      <vs-tab label="MyPals">
        <div class="vx-col w-full md:w-full lg:w-full xl:w-1/2 mx-auto">
          <vx-card title="My Pals">
            <b-tabs pills>
              <b-tab title="Followers">
                <my-followers :isProfile="true"/>
              </b-tab>
              <b-tab title="Followings">
                <my-follows :isProfile="true"/>
              </b-tab>
            </b-tabs>
          </vx-card>
        </div>
      </vs-tab>
      -->

    
    

    </vs-tabs>
    </div>
  </div>
  </div>

  </div>
</template>

<script>
import ProfileHeader from './ProfileHeader'
import PartnerPostsList from '../educator-profile/partner/PartnerPostsList'
import About from './about/About'
import MyFeeds from '@/layouts/components/coursepal/MyFeeds'
import MyFollows from '@/layouts/components/coursepal/MyFollows'
import MyFollowers from '@/layouts/components/coursepal/MyFollowers'
import SuggestedFollows from '@/layouts/components/coursepal/SuggestedFollows'
import PictureHolder from '@/layouts/components/coursepal/PictureHolder'
import Follow from './Follow'



import MyPostsV2 from './MyPostsV2'

export default {
  name: 'Profile',
  components: {
    ProfileHeader,
    About,
    MyFeeds,
    PartnerPostsList,
    MyFollows,
    MyFollowers,
    PictureHolder,
    SuggestedFollows,
    Follow,

   
    MyPostsV2
    
  },
  data () {
    return {
      pageLoaded : false,
      isNavOpen: false,
      wasSidebarOpen: null,
      isPictureHolderLoading: true
    }
  },
  watch: {
    '$route.params.user_name' (user) {
      this.$store.dispatch('profile/fetchFollowers', user)
      this.$store.dispatch('profile/fetchFollowings', user)
      this.$store.dispatch('profile/fetchMyFeeds', user).then(() => {
        this.isPictureHolderLoading = false
      })
    }
  },
  computed: {
    isEdit () {
      return this.$store.state.auth.userData.name === this.$route.params.user_name
    },
    isUniEmailVerify () {
      return this.$store.state.profile.isUniEmailVerify
    }
  },
  methods: {
    setPicturesLoaded () {
      this.isPictureHolderLoading = false
    }
  },
  mounted () {
    this.wasSidebarOpen = this.$store.state.reduceButton
    this.$store.commit('TOGGLE_REDUCE_BUTTON', true)
  },
  created () {
    this.$store.commit('newsfeed/SET_IS_LOADING', true)
    this.$store.dispatch('profile/fetchFollowers', this.$route.params.user_name)
    this.$store.dispatch('profile/fetchFollowings', this.$route.params.user_name)
    this.$store.dispatch('profile/fetchMyFeeds', this.$route.params.user_name).then(() => {
      this.isPictureHolderLoading = false
    })
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss";

// .vx-card .vx-card__collapsible-content.vs-con-loading__container {
//     overflow: hidden !important;
// }
.my-posts.mt-base{
  margin-top: 0 !important;
}
.profile-custom-tabs .ul-tabs{
  margin-bottom: 10px;
}
.profile-custom-tabs .vs-tabs--li{
  background-color: #fff;
  color: #626262 !important;
  // padding: .5rem 1rem !important;
  border-radius: 25px !important;
  margin-bottom: 0.5rem;
  margin-right: 14px;
  border: none !important;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,.14) !important;
}
.profile-custom-tabs .activeChild{
  background-color: #ea5455 !important;
  color: #fff !important;
}
.profile-custom-tabs .vs-tabs--li button.vs-tabs--btn{
  padding: .5rem 1rem !important;
}
.profile-custom-tabs .activeChild button{
  color: #fff !important;
}
.profile-custom-tabs .vs-tabs-primary .con-ul-tabs .activeChild button{
  color: #fff !important;
}
.profile-custom-tabs .line-vs-tabs{
  display: none;
}
.profile-custom-tabs .vs-tabs--ul{
  box-shadow: none !important;
}

@media only screen and (max-width: 600px) {
// .profile-custom-tabs .vs-tabs--ul.ul-tabs-center{
//     justify-content: left !important;
//   }
 .profile-custom-tabs  .vs-tabs-position-top .vs-tabs--ul{
  flex-wrap: unset !important
  }

.profile-custom-tabs .ul-tabs{
  margin-bottom: 0px;
}

.profile-custom-tabs .vs-tabs--li {
  margin-right: 14px !important;
  }
  
}

@media (max-width: 575.98px) {
  .profile-custom-tabs .vs-tabs--li {
  margin-right: 6px !important;
  }
 }
</style>
